body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.forget-password .input--user-email{
  padding: 0;
}
.forget-password  button{
  padding: 8px 15px !important;
  width: 100%;
}
/* Toolbar */
.custom-toolbar input{
  width: auto !important;
}
.custom-toolbar{
  width: 100%;
}
.custom-toolbar .custom-search {
  padding: 0 0 0 0;
  max-width: 30%;
  width: 100%;
  flex: 0 0 30%;
}
.custom-toolbar .custom-search .search-icon{
  left: 0.7rem;
}
.custom-toolbar .custom-search input{
  height:38px !important;
  width:100% !important;
}
.custom-toolbar .users-filter .users-button .select-role{
  margin-top: 0 !important;
}
.custom-filter .css-1uccc91-singleValue{
  padding: 0 15px !important;
}
.custom-filter .css-g1d714-ValueContainer{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-search > div{
  margin-top: 0 !important;
  padding: 0;
  position: relative;
}
.reset-filter > div{
  margin-top:0 ;
}
.custom-filter{
  padding-left: 15px;
  padding-right: 15px;
}
.reset-filter{
  padding-left: 10px;
}
.custom_dashboard .filter-btn button{
  padding-left: 10px;
  padding-top: 0;
  margin-left: 0;
}
.custom_dashboard .reset-filter button + button{
  margin-left: 5px;
}

.modal__btns {
  position: inherit
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast--error {
  background-color: #d32f2f !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  width: 100% !important;
  color: #fff !important;
}

.Toastify__toast--success {
  background-color: #58a35c !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  width: 100% !important;
  color: #fff !important;
}

.action-icon {
  color: #0b0a43;
  width: 25px;
}

.rotate {
  rotate: 90deg;
}

.action-icon:hover {
  color: #a7d1fb;
  cursor: pointer;
}

.dearchive-icon {
  color: #ff0000b8;
}

.dearchive-icon:hover {
  color: #ff0000b8;
}

.approve {
  color: #ff9142;
}

.approve:hover {
  color: #ff9142;
  cursor: default;
}

.MuiTooltip-tooltip {
  color: #0b0a43 !important;
  box-shadow: 0px 5px 10px 0px rgba(207, 207, 207, 0.5) !important;
  background-color: #fff !important;
}

.MuiTooltip-arrow {
  color: #fff !important;
}

@media(max-width:1199px){
  .custom_dashboard .MuiToolbar-root .filter-btn button{
    margin-left: 0;
  }
  .custom_dashboard .MuiToolbar-root{
    flex-wrap: wrap;
    min-height: auto;
  }
  .custom_dashboard .MuiToolbar-root .custom-search, .custom_dashboard .MuiToolbar-root .users-filter {
    padding: 0 !important;
    max-width: 50%;
    flex: 0 0 50%;
  }
  .custom_dashboard .MuiToolbar-root .users-filter{
    padding-left: 10px !important;
  }

.custom_dashboard .MuiToolbar-root .filter-btn{
  padding-right: 0;
}
}
@media(max-width:899px){
  .App header + div{
    height:auto;
    margin: auto 0 0;
  }
  .setpassword-container{
    padding: 0 15px;
  }
  .set-password-formGroup{
    padding: 0 !important;
  }
  .set-password-formGroup > div{
    height: auto !important;
    margin-bottom: 10px;
  }
  .loginWrapper img{
    margin-top: 20px;
  }
  .loginWrapper .input--user-email,  
  .loginWrapper .input--user-password, .loginWrapper .input--user-password + div{
    padding: 0 ;
  }
  .loginCard{
    padding:15px !important;
  }
  .forget-password {
    height:auto;
  }
  .forget-password .input{
    padding: 0 !important;
  }
  .forget-password .buttons--forgotten-password{
    padding-left: 0 !important;
  }
  .forget-password .controls-container{
    padding: 15px 15px;
  }
  body, #root, .App{
    height:100%;
  }
  .App{
    display: flex;
    flex-direction: column;
  }
  footer {
    margin-top: auto;
    height: auto !important;
    display: block !important;
    padding: 12px 15px !important;
}
footer p {
  margin-bottom: 5px;
}
.set-password-formGroup > div {
  padding-left:15px !important;
  padding-right:15px !important;
}
.dash_title{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.header_title{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.dash_title h1{
  font-size: 22px;
}
.dash_title .org__button{
  width:auto;
  padding-left: 10px;
}
.dash_title .org__button button{
  width:auto;
}
.page_title {
  padding-bottom: 1.5rem !important;
}
.custom_table_container{
  padding: 0 !important;
}
}
@media(max-width:767px){
  .main-res-header .select-org {
    width: 11rem !important;
    left: 11.2rem !important;
    font-size: 13px;
}
.main-res-header .brand_gcc{
  left: 4rem;
  margin-left: 4px;
  height: 28px;
}
.custom_dashboard .MuiToolbar-root {
  padding: 0 !important;
  flex-wrap: wrap;
  min-height: auto;
}
}
@media(max-width:575px){
/* responsive */
.custom_dashboard .MuiToolbar-root .custom-search, .custom_dashboard .MuiToolbar-root .users-filter {
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0 !important;
}
.custom_dashboard{
  padding: 20px 15px !important;
}
.custom_table_container .tab h4{
  font-size: 13px;
}
.custom_table_container .tab-active h4{
  font-size: 13px;
}
.custom_table_container .auto-table th{
  font-size: 13px;
}
.custom_dashboard .MuiToolbar-root .users-filter{
  margin-bottom: 10px;
}

.custom_dashboard .MuiToolbar-root .reset-filter{
  padding-left: 0 !important;
}
.custom_dashboard .auto-table{
  padding: 15px;
}
.custom_dashboard .auto-table .select-org{
  padding: 0;
  font-size: 13px;
}
.nav_menu_container{
  width: 33px;
  height: 33px;
}
.dash_title{
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.dash_title h1 {
  margin-bottom: 15px;
}
.nav_menu{
  height: 12px !important;
}
.main-res-header .user_details img {
  margin-right: 5px !important;
  width: 35px;
  height: 35px;
}
.main-res-header {
  flex-wrap: wrap;
  height: auto !important;
}
.main-res-header .select-org{
  width: 100% !important;
  position: relative !important;
  left: 0 !important;
  z-index: 300;
  order:7;
}
.brand_gcc {
  margin-left: 4px !important;
  position: relative !important;
  left: unset !important;
  height: 22px !important;
}
.nav_menu{

}
.brand_gcc_image{

}
.main-res-header button{
  padding: 8px 12px;
  font-size: 12px;
}
}